import React from "react";
import "./Register.css";
import "./Form.css";
import Form from "./Form";

const Register = () => {
  return (
    <div className="registerPage section__padding" id="register">
      <div className="registerContainer">
        <br />
        <h1 className="page-title">Register</h1>
        <Form />
      </div>
    </div>
  );
};

export default Register;
