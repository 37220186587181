import React from "react";
import Fade from "react-awesome-reveal";
import "./Guidelines.css";

const Guidelines = () => (
  <div className="guidelines-section" id="guidelines">
    <div className="app__guide-content flex__center">
      <div className="app__guide-content_guidelines">
        <Fade bottom>
          <h1 className="page-title">Guidelines</h1>
          <div className="card">
            <div className="para">
              <p className="p__opensans" id="guide__p">
                ⚙️ To Register For The Prelims Fill In The Details.
              </p>
              <p className="p__opensans" id="guide__p">
                ⚙️ In The City Section Fill In The City Where You Want To
                Register For Prelims.
              </p>
              <p className="p__opensans" id="guide__p">
                ⚙️ You Need To Upload 2 Of Your Remixes On Dropbox/Google Drive
                And Fill In The Link In The Mentioned Section. Make Sure The
                Link Can Be Viewed By Anyone Who Has It.
              </p>
              <p className="p__opensans" id="guide__p">
                ⚙️ You Can Also Send Us Links Of Your Mixes On Reverbnation
                Instead Of Dropbox/Google Drive.
              </p>
              <p className="p__opensans" id="guide__p">
                ⚙️ Please Upload The Best Quality Possible As These Mixes Will
                Be The Only Judging Criteria For Prelims.
              </p>
              <p className="p__opensans" id="guide__p">
                ⚙️ You Can Also Put The Additional Links Such As Your Youtube
                Links In The Other Link Section. If You Have Multiple Such
                Links, Send All Links Separated By A Comma.
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  </div>
);

export default Guidelines;
