import React from "react";
import Fade from "react-awesome-reveal";
import aboutImg from "../../images/bg/aboutUs.jpg";
import "./About.css";

const AboutUs = () => (
  <div className="app__aboutus section__padding" id="about">
    <Fade bottom>
      <h1 className="headtext__cormorant">About Us</h1>
    </Fade>
    <div className="app__wrapper app__aboutus__inner">
      <div id="aboutUs__image" className=" aboutUs__image app__wrapper_img">
        <Fade bottom>
          <div>
            <img className="synchro__frame_1" src={aboutImg} alt="header_img" />
          </div>
        </Fade>
      </div>
      <div className="app__wrapper_info">
        <div className="app__aboutus-content_about">
          <Fade bottom>
            <p className="p__opensans" id="aboutus__p">
              Known For Providing An Unparalleled And Highly Competitive Stage,
              DJ War Is The Grand Stage For All EDM Enthusiasts And Budding
              Artists Of The Country. The Highly Anticipated Finale Has Long
              Been One Of The Most Exciting Events Of The Fest, With
              Participants Streaming In From All Across The Country. As A
              Budding Artist, You Have The Chance To Not Only Get Your Talent
              Recognised At A National Level But Also Win Exciting Prizes, All
              The While Levelling Up And Rising To New Heights In The EDM Scene.
              This Time, DJ War Is Coming To Your City With Its Preliminary
              Rounds Being Organised In Online And Physical Modes In 12 Cities.
              Grab Your DAW And Get Ready To Spread Your Wings And Flaunt Your
              Flair With This Absolutely Amazing Opportunity To Break It Into
              The Big Leagues. Qualifying As Finalists Earns You A Chance To
              Perform In Front Of A Crowd Of Thousands And A Stellar Jury At The
              Color Run During Antaragni, An Event Filled With The Vibrance, Joy
              And Vigour, Symbolizing Everything Electronica Is About.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  </div>
);

export default AboutUs;
