import React from "react";
import { city } from "./timelineData";
import Fade from "react-awesome-reveal";
import "./Timeline.css";

const Schedule = ({ data }) => (
  <div
    className="app_schedule app__wrapper section__padding"
    style={{ overflow: "hidden" }}
    id="timeline"
  >
    <Fade bottom>
      <h1 className="headtext__cormorant">Timeline</h1>
    </Fade>
    {data.map((event) => (
      <div className="schedule__block">
        <div
          className="schedule__bgImg"
          style={{ backgroundImage: `url("${event.img.url}")` }}
        ></div>

        <div className="schedule__place">
          <p className="schedule__city">{event.name}</p>
        </div>
        <div className="schedule__content">
          <h1 className="schedule__date">{event.text1}</h1>
        </div>
      </div>
    ))}
  </div>
);

export default Schedule;
