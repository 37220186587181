import React from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";
import "./ContactCard.css";

const ContactCard = ({ data }) => {
  return (
    <div className="contact-card">
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div className="profile-image">
              <img className="pfp" src={data.img.url} alt="profile_pic" />
              <div className="name">{data.name}</div>
              {/* <div className="name">DJ War</div> */}
            </div>
          </div>

          <div className="flip-card-back">
            <br />
            <div className="details phone">
              <BsTelephoneFill /> &nbsp;
              {data.text1}
            </div>
            <br />
            <br />
            <div className="details email">
              <AiFillMail /> &nbsp; {data.text2}
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
