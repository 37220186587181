import React from "react";
import Typewriter from "typewriter-effect";
import Zoom from "react-awesome-reveal";
// import djwarImg from "../../images/bg/mem.jpg";
import "./Home.css";

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <Zoom>
        <h1 className="app__header-h1">
          <span style={{ color: "whitesmoke", fontFamily: "homeTitle" }}>
            DJ WAR - 2024
          </span>
          <br />

          <Typewriter
            options={{
              strings: [
                '<span style="font-family:homeTitle"> Roadtrip of ..</span>',
                '<span style="font-family:homeTitle"> Antaragni  </span>',
                '<span style="font-family:homeTitle"> IIT Kanpur </span>',
              ],
              autoStart: true,
              loop: true,
              typeSpeed: 100,
              cursor: ".",
              color: "green",
            }}
          />
        </h1>
      </Zoom>
    </div>

    {/* <div className="app__wrapper_img">
      <Zoom>
        <img className="synchro__frame_1" src={djwarImg} alt="header_img" />
      </Zoom>
    </div> */}
  </div>
);

export default Header;
