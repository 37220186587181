import { Fade, Zoom } from "react-awesome-reveal";
import "./Sponsors.css";

const Sponsors = ({ data }) => {
  return (
    <div className="sponsors-section" id="sponsors">
      <Fade bottom>
        <br />
        <h1 className="page-title">Sponsors</h1>
      </Fade>
      <Fade bottom cascade>
        {data.length <= 0 ? (
          <h1>To be announced... </h1>
        ) : (
          <div className="sponsors-container">
            {data.map((sponsor) => {
              return (
                <Zoom>
                  <div key={sponsor.name} className="sponsors-card">
                    <img
                      className="sponsors-img"
                      src={sponsor.img.url}
                      alt={sponsor.name}
                    />
                    <div className="textBox">
                      <p className="sponsorPlace">{sponsor.name}</p>
                    </div>
                  </div>
                </Zoom>
              );
            })}
          </div>
        )}
      </Fade>
    </div>
  );
};

export default Sponsors;
