import Header from "./components/header/Navbar";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Timeline from "./components/timeline/TimelineComp";
// import Memories from "./components/gallery/GalleryComp";
import Sponsors from "./components/sponsors/SponsorsComp";
import Contacts from "./components/contact/ContactUs";
import ScrollUp from "./components/utils/scrollUp";
import Guidelines from "./components/guidelines/Guidelines";
import Register from "./components/register/RegisterComp";
import { useState, useEffect } from "react";
import Loader from "./components/utils/Loader";
import { getSingleDoc } from "./config/firebaseFirestore";
const App = () => {
  const [loading, setLoading] = useState(true);
  const [sponsors, setSponsors] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [contacts, setContacts] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const rawData = await getSingleDoc("WebContents", "djwar");
      let contacts = [];
      let schedule = [];
      let sponsors = [];
      for (let i = 0; i < rawData.data.length; i++) {
        switch (rawData.data[i].flag) {
          case "contact":
            contacts.push(rawData.data[i]);
            break;
          case "sponsor":
            sponsors.push(rawData.data[i]);
            break;
          case "schedule":
            schedule.push(rawData.data[i]);
            break;
        }
      }
      setContacts(contacts);
      setSchedule(schedule);
      setSponsors(sponsors);
      // console.log(rawData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <div className="app">
      <Header />
      <ScrollUp />
      <Home />
      <About />
      <Timeline data={schedule} />
      {/* <Memories /> */}
      <Sponsors data={sponsors} />
      <Guidelines />
      <Register />
      <Contacts data={contacts} />
    </div>
  );
};

export default App;
