import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { firebaseApp } from "../../config";
import "./Form.css";
import { getSingleDoc } from "../../config/firebaseFirestore";

const db = getFirestore(firebaseApp);

const Form = () => {
  const [formData, setFormData] = useState([]);

  const [cities, setCities] = useState([]);
  const fetchData = async () => {
    try {
      const rawData = await getSingleDoc("WebContents", "roadtripsCities");
      let cities = [];
      for (let i = 0; i < rawData.data.length; i++) {
        if (rawData.data[i].flag === "djwar") {
          cities.push(rawData.data[i].city);
        }
      }
      setCities(cities);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [cityName, setCityName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [songLink, setSongLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [otherLink, setOtherLink] = useState("");
  const [moreSongLink, setMoreSongLink] = useState("");

  // generating Timestamp-ID
  function generateTimestampId() {
    const timestamp = firebase.firestore.Timestamp.now();
    const timestampString = timestamp.toDate().getTime().toString();
    const cleanedTimestampString = timestampString.replace(/[^a-zA-Z0-9]/g, "");
    return cleanedTimestampString;
  }

  // reset form

  const resetForm = () => {
    setName("");
    setAlternateNumber("");
    setCityName("");
    setContactNumber("");
    setEmailId("");
    setFacebookLink("");
    setInstagramLink("");
    setSongLink("");
    setMoreSongLink("");
    setOtherLink("");
  };

  // Handling Form-Submission
  async function handleFormSubmit(e) {
    e.preventDefault();
    const id = generateTimestampId();
    if (
      name != "" &&
      emailId != "" &&
      cityName != "" &&
      contactNumber != "" &&
      facebookLink != "" &&
      instagramLink != "" &&
      songLink != ""
    ) {
      try {
        await setDoc(doc(db, "djwar24", id), {
          name: name.toUpperCase(),
          email: emailId,
          city: cityName,
          phone: contactNumber,
          altPhone: alternateNumber,
          songLink: songLink,
          instaLink: instagramLink,
          fbLink: facebookLink,
          otherLink: otherLink,
          moreSongLink: moreSongLink,
        });
        resetForm();
        alert("Form Submitted Successfully");
      } catch (err) {
        console.error(err);
        alert("Error occurred while submitting the form !!");
      }
    } else {
      alert("Please fill all the required fields !");
    }
  }

  return (
    <div className="registration-form">
      <form className="form">
        <div className="inputContainer">
          <div className="containerInput">
            <input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
              placeholder="Name*"
              type="text"
            />
          </div>
          <div className="containerInput">
            <input
              value={emailId}
              onChange={(e) => {
                setEmailId(e.target.value);
              }}
              required
              placeholder="Email Id*"
              type="email"
            />
          </div>
          <div className="containerInput selectInput">
            <select
              value={cityName}
              onChange={(e) => {
                setCityName(e.target.value);
              }}
              required
            >
              <option value="">— Select City* —</option>
              {cities.map((city) => {
                return <option value={city}>{city}</option>;
              })}
            </select>
          </div>
          <div className="containerInput">
            <input
              value={contactNumber}
              onChange={(e) => {
                setContactNumber(e.target.value);
              }}
              required
              placeholder="Contact No.*"
              type="number"
            />
          </div>
          <div className="containerInput">
            <input
              value={alternateNumber}
              onChange={(e) => {
                setAlternateNumber(e.target.value);
              }}
              placeholder="Alternate No."
              type="number"
            />
          </div>
          <div className="containerInput">
            <input
              value={songLink}
              onChange={(e) => {
                setSongLink(e.target.value);
              }}
              required
              placeholder="Song Link*"
              type="url"
            />
          </div>
          <div className="containerInput">
            <input
              value={moreSongLink}
              onChange={(e) => {
                setMoreSongLink(e.target.value);
              }}
              placeholder="More Song Links"
              type="text"
            />
          </div>
          <div className="containerInput">
            <input
              value={facebookLink}
              onChange={(e) => {
                setFacebookLink(e.target.value);
              }}
              required
              placeholder="Facebook Link*"
              type="url"
            />
          </div>
          <div className="containerInput">
            <input
              value={instagramLink}
              onChange={(e) => {
                setInstagramLink(e.target.value);
              }}
              required
              placeholder="Instagram Link*"
              type="url"
            />
          </div>
          <div className="containerInput">
            <input
              name="otherLink"
              value={otherLink}
              onChange={(e) => {
                setOtherLink(e.target.value);
              }}
              placeholder="Other Link"
              type="url"
            />
          </div>
        </div>
        <div className="buttonContainer">
          <button className="btn" onClick={handleFormSubmit}>
            <strong>Submit</strong>
            <div id="container-stars">
              <div id="stars"></div>
            </div>
            <div id="glow">
              <div className="circle"></div>
              <div className="circle"></div>
            </div>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
