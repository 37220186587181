import React from "react";
import ContactCard from "./ContactCard";
import { Slide } from "react-awesome-reveal";
import { contactsData } from "./contactData";
import "./Contact.css";

const Contact = ({ data }) => (
  <div className="app__contact app__bg section__padding " id="contact">
    <div style={{ position: "relative" }}>
      <h1
        className="headtext__cormorant"
        // style={{ textAlign: "center", marginTop: "3vh" }}
      >
        Contact Us
      </h1>
      {/* <h2 style={{ color: "white", textAlign: "center" }}>Get in Touch</h2> */}
    </div>
    <br />
    <br />
    <br />
    <div className="contact-container">
      {data.map((contact) => (
        <Slide direction="right">
          <ContactCard data={contact} />
        </Slide>
      ))}
    </div>
  </div>
);

export default Contact;
